
import { defineComponent, reactive, ref, onMounted  } from "vue";
import postUpdate from "@/components/modals/forms/NewTargetModal.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Pagination from "v-pagination-3";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useStore } from "vuex";

import axios from "axios";


import eventBus from '../../../event-buss'
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-11",
  components: { postUpdate, Pagination },
  props: {
    widgetClasses: String,
  },
  data(){
    return {
      page: 1
    }
  },
  setup(props, context) {
    // console.log(props)
    const store = useStore();

    onMounted(() => {
      setCurrentPageTitle("Posts");
    });

    const editPost =  id => {
      let posts = JSON.parse(JSON.stringify(store.getters.GET_POSTS))
      let filter = posts.filter( v => v._id === id )
      if(filter.length){
        eventBus.$emit('update-form', filter);
      }
    }

    const confirmAction = id =>{
      Swal.fire({
        text: "Are you sure?!",
        icon: "error",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Delete post',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },
      }).then( r => {
        if(r.isConfirmed)
          deletePost(id);
      });
    }
    const deletePost = id =>{
      store.dispatch(Actions.DELETE_POST, id).then( () =>{
        store.dispatch(Actions.GET_UPDATES)
      });
    }

    let per_page = ref(10)
    let shownPosts = {
        start: ref(0),
        stop: ref(per_page.value - 1 )
    };
    let listings = {
      total: ref(store.getters.GET_POSTS.length),
      per_page,
    };

    const paginate = page => {
      let lpp = listings.per_page.value;
      let start =  ( lpp * page ) - lpp;
      let stop =  lpp * page;
      shownPosts.start.value = start - 1;
      shownPosts.stop.value = stop - 1;
      // console.log(page);
    };


    return {
      listings,
      shownPosts,
      paginate,
      confirmAction,
      editPost,
      deletePost,
      posts: store.getters.GET_POSTS
    };
  },
});
